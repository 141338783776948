import _ from "lodash";
import groups from "../../types/claim_pack_groups"

export default {
  namespaced: true,
  state: {
    documents: {},
    selectedDocumentIds: [],
  },
  actions: {
    fetchFinalClaimPack({commit, rootState, dispatch}) {
      const claim_id = rootState.auth.user.claim.selectedClaim.id;
      this.$axios.get(process.env.VUE_APP_API_URL + '/api/claim-pack/list/' + claim_id, {
        params: {
          orderField: 'id',
          orderDirection: 'DESC'
        }
      })
        .then(({data = []}) => {
          commit('setFinalClaimPack', data.data || {});
        }).catch(error => {
          dispatch('setError', error, {root: true})
        });
    },
    download({dispatch, commit}, file) {
      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/claim-pack/download/${file.id}`, {
        responseType: 'arraybuffer'
      })
        .then(({data}) => {
          let blob = new Blob([data])
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = file.file_name
          link.click();
        })
        .then(() => {
          commit('setReadDocument', file);
        })
        .catch(error => {
          dispatch('setError', error, {root: true})
        });
    },
    updateSelection({commit}, {documentId, selectionState}) {
        commit('updateSelection', {documentId, selectionState})
    },
    clearSelectedDocuments({commit}) {
        commit('updateSelectedDocumentIds');
    },
    downloadSeveralFiles({state, commit, dispatch}) {
      this.$axios.get(`${process.env.VUE_APP_API_URL}/api/claim-pack/download-several/`, {
        responseType: 'arraybuffer',
        params: {
          'file_ids': state.selectedDocumentIds,
        },
      }).catch(error => {
          dispatch('setError', error, {root: true})
      }).then(({data}) => {
          let blob = new Blob([data])
          let link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'ClaimPack.zip'
          link.click();

          for (const documentId of state.selectedDocumentIds) {
            commit('setReadDocumentById', documentId);
          }

          commit('updateSelectedDocumentIds')

      });
    },
    share({dispatch, rootState}, {document, email}) {
      const claim_id = rootState.auth.user.claim.selectedClaim.id;
      return this.$axios.post(`${process.env.VUE_APP_API_URL}/api/claim-pack/give-read-permission`, {
        group_id: document.group_id,
        email,
        claim_id
      }).catch(error => {
        dispatch('setError', error, {root: true});
        return error;
      });
    }
  },
  mutations: {
    setFinalClaimPack(state, documents) {
      if (documents.narrative !== undefined) {
        documents.narrative = documents.narrative.slice(0, 2);
      }
      if (documents.discovery_report !== undefined) {
        documents.discovery_report = documents.discovery_report.slice(0, 2);
      }
      state.documents = documents;
    },
    setReadDocument(state, document) {
      const changeDocument = state.documents[document.group_name].find(item => item.id === document.id);
      changeDocument.read = true;
    },
    setReadDocumentById(state, documentId) {
      for (const groupName in state.documents) {
        const changeDocument = state.documents[groupName].find(item => item.id === documentId);

        if (changeDocument !== undefined) {
          changeDocument.read = true;
        }
      }
    },
    updateSelection(state, {documentId, selectionState}) {

      if (selectionState) {
        state.selectedDocumentIds.push(documentId);
      } else {
        const index = state.selectedDocumentIds.findIndex(id => id === documentId)
        state.selectedDocumentIds.splice(index, 1);
      }

    },
    updateSelectedDocumentIds(state) {
      state.selectedDocumentIds = []
    }
  },
  getters: {
    selectedDocumentIds(state) {
      return state.selectedDocumentIds;
    },
    documents(state) {
      return _.cloneDeep(state.documents);
    },
    isReadAllDocuments(state) {
      if (!_.isEmpty(state.documents)) {
        for (let type in groups) {
          if (state.documents[type] === undefined) {
            continue;
          }
          if (state.documents[type][0].read === false) {
            return false;
          }
        }
        return true;
      }
      return null;
    }
  }
}
